import React from "react"
import Img from "gatsby-image"
import { Link, useTranslation, Trans } from "gatsby-plugin-react-i18next"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

import "./onas.scss"

export default ({ data }) => {
  const { t } = useTranslation()
  const LinkText = ({ href, children }) => (
    <Link to={href || ""}>{children}</Link>
  )
  return (
    <Layout className="page about">
      <SEO title="O nás" />
      <h1>{t("about.headline")}</h1>

      <br />
      <h2>{t("about.subheadline")}</h2>

      <div className="about__content">
        <div className="about__photo">
          <Img fluid={data.file.childImageSharp.fluid} alt="David Suchý" />
          <span>(C) Marcel Skýba</span>
        </div>

        <div>
          <p dangerouslySetInnerHTML={{ __html: t("about.content1") }} />
          <p>
            <Trans
              i18nKey="about.content2"
              t={t}
              components={{
                bold: <b />,
                servis: <LinkText href={t("about.link1")} />,
                builds: <LinkText href={t("about.link2")} />,
                contact: <LinkText href={t("about.link3")} />,
              }}
            />
          </p>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    file(relativePath: { eq: "david_suchy.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
